import React from "react"
import ArrowZ from "../../common/ArrowZ"

const Main = () => {
  return (
    <div className="p-company-main">
      <div className="p-company-main__wrapper">
        <ul className="p-company-main__list">
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">名称</dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <div className="p-company-main__item-dd-block">
                  株式会社CRISP
                </div>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">設立</dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <div className="p-company-main__item-dd-block">
                  2014年7月1日
                </div>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">所在地</dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <div className="p-company-main__item-dd-block">
                〒105-0001 東京都港区虎ノ門1-10-5 KDX虎ノ門一丁目 11F WeWork内 
                  <a
                    className="js-hover p-company-main__item-dd-block-link"
                    href="https://maps.app.goo.gl/iv4q6MNsJHnXjZfeA"
                    data-hover-in-time="600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="c-link__main-inner">(Google Map)</span>
                  </a>
                </div>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">資本金</dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <div className="p-company-main__item-dd-block">
                  50,000,000円
                </div>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">事業内容</dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <div className="p-company-main__item-dd-block">
                  カスタムサラダレストラン「CRISP SALAD WORKS」の展開を通じて、
                  <br className="u-none__sp" />
                  テクノロジーで顧客体験を非連続な成長と高い収益率を実現する新しい外食企業「コネクティッド・レストラン」を構築する
                </div>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">役員</dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <div className="p-company-main__item-dd-block">
                  <dl className="p-company-main__item-dd-dl">
                    <dt className="p-company-main__item-dd-dt">代表取締役</dt>
                    <dd className="p-company-main__item-dd-dd">宮野浩史</dd>
                  </dl>
                  <dl className="p-company-main__item-dd-dl">
                    <dt className="p-company-main__item-dd-dt">社外取締役</dt>
                    <dd className="p-company-main__item-dd-dd">樋口健（三菱商事株式会社 コンシューマー産業グループCEOオフィス 事業構想・デジタル戦略担当）</dd>
                  </dl>
                  <dl className="p-company-main__item-dd-dl">
                    <dt className="p-company-main__item-dd-dt">社外取締役</dt>
                    <dd className="p-company-main__item-dd-dd">淺田慎二（One Capital株式会社 代表取締役CEO）</dd>
                  </dl>
                </div>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">株主</dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <div className="p-company-main__item-dd-block">
                  <ul className="p-company-main__item-dd-list">
                    <li className="p-company-main__item-dd-item">宮野浩史</li>
                    <li className="p-company-main__item-dd-item">
                      <a
                        className="js-hover p-company-main__item-dd-block-link"
                        href="https://www.mitsubishicorp.com/jp/ja/"
                        data-hover-in-time="600"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="c-link__main-inner">三菱商事</span>
                      </a>
                    </li>
                    <li className="p-company-main__item-dd-item">
                      <a
                        className="js-hover p-company-main__item-dd-block-link"
                        href="http://www.onecapital.jp/"
                        data-hover-in-time="600"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="c-link__main-inner">One Capital</span>
                      </a>
                    </li>
                    <li className="p-company-main__item-dd-item">
                      <a
                        className="js-hover p-company-main__item-dd-block-link"
                        href="https://www.sbic.co.jp/"
                        data-hover-in-time="600"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="c-link__main-inner">東京投資育成</span>
                      </a>
                    </li>
                    <li className="p-company-main__item-dd-item">その他</li>
                  </ul>
                </div>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">
                売上その他データ
              </dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <a
                  className="js-hover p-company-main__item-dd-link"
                  data-hover-in-time="600"
                  href="/openness/"
                >
                  <span className="c-link__main-inner">OPENNESS にて公開</span>
                </a>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">
                お問い合わせ
              </dt>
              <dd className="p-company-main__item-dd">
                <a
                  className="js-hover c-txt__min p-company-main__item-dd-link"
                  data-hover-in-time="600"
                  href="https://crispsupport.zendesk.com/hc/ja/requests/new"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="c-link__main-inner">
                    お問い合わせフォーム
                  </span>
                  <ArrowZ />
                </a>
              </dd>
            </dl>
          </li>
          <li className="p-company-main__item">
            <dl className="p-company-main__item-dl">
              <dt className="c-txt__min p-company-main__item-dt">
                電話
              </dt>
              <dd className="c-txt__min p-company-main__item-dd">
                <div className="p-company-main__item-dd-block">
                  050-3160-0301
                </div>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Main
